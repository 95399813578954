import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowLight,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import SignUp from "../../modules/SignUp";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import RepositoryListRow from "../../modules/RepositoryListRow";

function EntryProcess() {
    useEffect(() => {
        document.title += " - Entry Process";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={11} sm={6} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="entry" width={"90%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Entry Process</BannerTitle>
                        <BannerSubTitle>
                            Submitting is easy as applying to a competition and
                            mailing off a homebrew. Locations and labels are
                            generated for you and any payments are handled
                            online.
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Entry Process
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            Submitting is easy as applying to a competition and
                            mailing off a homebrew. Locations and labels are
                            generated for you and any payments are handled
                            online.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRow type="flex" justify="center">
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                One Stop Entry Process
                            </h2>
                            <hr />
                            <p>
                                The entry process is incredibly easy! Through
                                Brewerwall, homebrewers can search for
                                competitions, enter those competitions by
                                creating a user account. Once in, they can
                                define their entries, make entry fees and see
                                the judging status.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Generated Entry Labels
                            </h2>
                            <hr />
                            <p>
                                No more sloppy handwriting or creating a custom
                                label. Based on your entry information,
                                Brewerwall will generate a label for you!
                                Included on each label will be a barcode to help
                                with the sorting and judging processes later on.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Entry Status and Scoresheets
                            </h2>
                            <hr />
                            <p>
                                Once your homebrew is in the mail, Brewerwall is
                                your dashboard for where it's at in the
                                competition process. You will have insights on
                                when your entry has been recieved and when it
                                has been judged. When juding is complete,
                                getting your scoresheets will be a breeze.
                                Through your entry dashboard, they can view
                                individual scoresheets.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Payments Made Easy
                            </h2>
                            <hr />
                            <p>
                                Through Brewerwall, you can supply any entry
                                fees that are required of the competition. This
                                is done safely and securely.
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <LayoutRowLight type="flex" justify="center" align="top">
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <h1>Sign Up For Updates and Beta Access</h1>
                        <SignUp style={{ marginBottom: "20px" }} size="large" />
                    </Col>
                </LayoutRowLight>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default EntryProcess;
