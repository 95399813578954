import React, { useState } from "react";
import { Button, Modal, Input, notification } from "antd";
import Signup from "../../api/signup";

function SignUp(props) {
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    function signUpSuccess() {
        notification["success"]({
            message: "Sign Up Success",
            description:
                "Your information has been recorded and we will let you know of future updates and beta access!"
        });
    }

    function signUpError(errorMessage) {
        notification["error"]({
            message: "Whoops!",
            description: errorMessage
        });
    }

    function handleOk() {
        setSubmitting(true);

        Signup.post(firstName, lastName, email)
            .then(function() {
                signUpSuccess();
            })
            .catch(function(error) {
                signUpError(error.response.data.errors[0].detail);
            })
            .finally(function() {
                setShowSignUpModal(false);
                setSubmitting(false);
                setFirstName("");
                setLastName("");
                setEmail("");
            });
    }

    return (
        <span style={props.style}>
            <Button
                type="primary"
                size={props.size}
                onClick={() => setShowSignUpModal(true)}
                style={props.buttonStyle}
            >
                Sign Up
            </Button>
            <Modal
                title="Sign Up for the Beta"
                visible={showSignUpModal}
                onOk={handleOk}
                onCancel={() => setShowSignUpModal(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setShowSignUpModal(false)}
                        disabled={submitting}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="signUp"
                        href="#"
                        type="primary"
                        loading={submitting}
                        onClick={handleOk}
                        style={{ marginLeft: "5px" }}
                    >
                        Sign Up
                    </Button>
                ]}
            >
                <p>
                    Brewerwall is still working hard to become a centralized
                    location for homebrew competitions. Sign up to recieve
                    emails for anouncements and beta access.
                </p>
                <Input
                    id="signUpFirstName"
                    placeholder="First Name"
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                />
                <br />
                <br />
                <Input
                    id="signUpLastName"
                    placeholder="Last Name"
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                />
                <br />
                <br />
                <Input
                    id="signUpEmail"
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                />
            </Modal>
        </span>
    );
}

export default SignUp;
