import styled from "styled-components";

export const Anchor = styled.a`
    display: block;
    text-align: center;
    padding: 14px 10px 10px 10px;
    border-radius: 8px;
    color: white;
    -webkit-transition: background-color 200ms ease-out;
    -moz-transition: background-color 200ms ease-out;
    -o-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
    :hover {
        background-color: rgb(0, 0, 0, 0.25);
        color: white;
    }
    div{
        margin-top: 5px;
    }
`;
