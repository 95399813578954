import React from "react";
import beer from "./svg/beer.svg";
import blog from "./svg/blog.svg";
import data from "./svg/data.svg";
import entry from "./svg/entry.svg";
import flight from "./svg/flight.svg";
import grain from "./svg/grain.svg";
import hops from "./svg/hops.svg";
import judging from "./svg/judging.svg";
import scoresheet from "./svg/scoresheet.svg";
import srm from "./svg/srm.svg";
import yeast from "./svg/yeast.svg";
import medal from "./svg/medal.svg";
import { IconImage } from "./styles.js";

function determineIconType(props) {
    switch (props.type) {
        case "data":
            return data;
        case "entry":
            return entry;
        case "flight":
            return flight;
        case "grain":
            return grain;
        case "hops":
            return hops;
        case "judging":
            return judging;
        case "medal":
            return medal;
        case "scoresheet":
            return scoresheet;
        case "srm":
            return srm;
        case "yeast":
            return yeast;
        case "blog":
            return blog;
        case "beer":
        default:
            return beer;
    }
}

function BrewerwallIcon(props) {
    return <IconImage alt="icon" src={determineIconType(props)} {...props} />;
}

export default BrewerwallIcon;
