import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowLight,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import SignUp from "../../modules/SignUp";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import RepositoryListRow from "../../modules/RepositoryListRow";

function Scoresheets() {
    useEffect(() => {
        document.title += " - Scoresheets";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={10} sm={6} md={4} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="scoresheet" width={"65%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Scoresheets</BannerTitle>
                        <BannerSubTitle>
                            No more hassles with juggling scoresheets. The
                            streamlining of completing and returning scoresheets
                            is here!
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Scoresheets
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            No more are the days of spreadsheets and paperwork.
                            A centralized place to manage all of the competition
                            hassles.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRow type="flex" justify="center">
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Easy to Manage Scoresheets
                            </h2>
                            <hr />
                            <p>
                                No more hassles with juggling scoresheets. With
                                the ability to print, scan and store all of the
                                competition's scoresheets in a central location,
                                it makes things incredibily easy for everyone.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Printable Scoresheets
                            </h2>
                            <hr />
                            <p>
                                In preperation for judging, scoresheets can be
                                printed with pre-filled information for the
                                entry and judges information. Scoresheets will
                                also include the same barcode that the entry
                                label has to help facilitate the data entry
                                process. All you need is a simple barcode
                                scanner.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Upload Scores and Scans
                            </h2>
                            <hr />
                            <p>
                                Once complete, scoresheets can be scanned and
                                uploaded. This can alleviate the need for
                                scoresheets to be returned by mail, however
                                nothing can stop you from that if you want to.
                                This also acts as a central location for brewers
                                to always keep a digital copy of their
                                scoresheet.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Centralized Storage for Brewers
                            </h2>
                            <hr />
                            <p>
                                Brewers will be able to log into Brewerwall and
                                review all of their scoresheets and placements.
                                This can also serve as a stage to show a brewers
                                accomplishements.
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <LayoutRowLight type="flex" justify="center" align="top">
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <h1>Sign Up For Updates and Beta Access</h1>
                        <SignUp style={{ marginBottom: "20px" }} size="large" />
                    </Col>
                </LayoutRowLight>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default Scoresheets;
