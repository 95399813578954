import React from "react";
import { Layout } from "antd";
import MenuBar from "../MenuBar";
import FooterBar from "../FooterBar";

function PrimaryLayout(props) {
    return (
        <Layout style={{ backgroundImage: "url('/img/bg.png')" }}>
            <MenuBar />
            <Layout.Content>{props.children}</Layout.Content>
            <FooterBar />
        </Layout>
    );
}

export default PrimaryLayout;
