import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import { useHistory } from "react-router-dom";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowDark,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
} from "../../modules/PrimaryLayout/styles";
import { DataCard, CodeBlock } from "./styles";
import * as Link from "../../modules/Links";
import BrewerwallIcon from "../../modules/BrewerwallIcon";

const responseExample = {
    data: [
        {
            type: "yeasts",
            id: "1",
            attributes: {
                laboratory: "White Labs",
                strain: "WLP001",
                name: "California Ale Yeast",
                description: "Yeast's Description",
                attenuation_min: 73,
                attenuation_max: 80,
                flocculation: "Medium",
                temperature_min: 68,
                temperature_max: 73,
                tolerance: 15,
                form: "Liquid",
            },
            relationships: [],
            links: { self: "http://api.brewerwall.here/yeasts/1" },
        },
    ],
    included: [],
};

function Data() {
    const history = useHistory();

    useEffect(() => {
        document.title += " - Data";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={10} sm={6} md={4} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="data" width={"70%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Data</BannerTitle>
                        <BannerSubTitle>
                            A full repository of data that can be searched and
                            connected through our API.
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Data
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            A full repository of data that can be searched and
                            connected through our API.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRowDark type="flex" justify="center" align="top">
                        <Col xs={24} style={{ marginTop: 20 }}>
                            <h1 style={{ color: "white", textAlign: "center" }}>
                                Forever Access to Data Repositories
                            </h1>
                        </Col>
                        <Col xs={20} sm={{ span: 10 }} md={{ span: 4 }}>
                            <DataCard
                                onClick={() => history.push(Link.styles.url)}
                            >
                                <BrewerwallIcon type="beer" width={80} />
                                <h2
                                    style={{
                                        color: "white",
                                        marginTop: "10px",
                                    }}
                                >
                                    Styles
                                </h2>
                                <div style={{ color: "white" }}>
                                    Style definitions from versions of the BA
                                    and BJCP guidelines.
                                </div>
                            </DataCard>
                        </Col>
                        <Col
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            md={{ span: 4, offset: 1 }}
                        >
                            <DataCard
                                onClick={() => history.push(Link.hops.url)}
                            >
                                <BrewerwallIcon type="hops" width={80} />
                                <h2
                                    style={{
                                        color: "white",
                                        marginTop: "10px",
                                    }}
                                >
                                    Hops
                                </h2>
                                <div style={{ color: "white" }}>
                                    A massive collection of hop information and
                                    substitution options.
                                </div>
                            </DataCard>
                        </Col>
                        <Col
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            md={{ span: 4, offset: 1 }}
                        >
                            <DataCard
                                onClick={() => history.push(Link.yeasts.url)}
                            >
                                <BrewerwallIcon type="yeast" width={80} />
                                <h2
                                    style={{
                                        color: "white",
                                        marginTop: "10px",
                                    }}
                                >
                                    Yeasts
                                </h2>
                                <div style={{ color: "white" }}>
                                    Discrete data of all the popular strains
                                    from Wyeast, White Labs and more.
                                </div>
                            </DataCard>
                        </Col>
                        <Col
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            md={{ span: 4, offset: 1 }}
                        >
                            <DataCard
                                onClick={() => history.push(Link.srms.url)}
                            >
                                <BrewerwallIcon type="srm" width={80} />
                                <h2
                                    style={{
                                        color: "white",
                                        marginTop: "10px",
                                    }}
                                >
                                    Srm
                                </h2>
                                <div style={{ color: "white" }}>
                                    A color wheel of your favorite beers
                                    referencing srm to a similar hex value
                                </div>
                            </DataCard>
                        </Col>
                    </LayoutRowDark>
                </Fade>
                <LayoutRow type="flex" justify="center" align="top">
                    <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 8 }}>
                        <h2 style={{ color: "white" }}>Simple Requests</h2>
                        <h4 style={{ color: "white" }}>
                            Perform a GET request to retrieve all yeasts
                        </h4>
                        <CodeBlock>
                            $ curl http://api.brewerwall.com/yeasts
                        </CodeBlock>
                        <h4 style={{ color: "white" }}>
                            Perform a GET request on a specific ID{" "}
                        </h4>
                        <CodeBlock>
                            $ curl http://api.brewerwall.com/yeasts/1
                        </CodeBlock>
                        <h4 style={{ color: "white" }}>
                            Perform a GET request with query parameters to
                            filter
                        </h4>
                        <CodeBlock>
                            $ curl
                            http://api.brewerwall.com/yeasts?temperature=80
                        </CodeBlock>
                    </Col>
                    <Col xs={0} sm={{ span: 22 }} lg={{ span: 10, offset: 1 }}>
                        <h2 style={{ color: "white" }}>
                            Responses in JSON Api Spec
                        </h2>
                        <h4 style={{ color: "white" }}>
                            All responses will adhear to the{" "}
                            <a
                                href="https://jsonapi.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                JSON Api Spec
                            </a>
                        </h4>
                        <CodeBlock>
                            <pre>
                                {JSON.stringify(responseExample, null, 2)}
                            </pre>
                        </CodeBlock>
                    </Col>
                </LayoutRow>
            </PrimaryLayout>
        </div>
    );
}

export default Data;
