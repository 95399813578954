import api from "./";

class Hops {
    route = "/hops";

    get = filter => {
        return api.get(this.route);
    };
}

export default new Hops();
