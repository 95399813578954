import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./modules/ScrollToTop";
import Home from "./pages/Home";
import Hops from "./pages/Hops";
import Srms from "./pages/Srms";
import Styles from "./pages/Styles";
import Yeasts from "./pages/Yeasts";
import Data from "./pages/Data";
import About from "./pages/About";
import Competition from "./pages/Competition";
import EntryProcess from "./pages/EntryProcess";
import FlightAutomation from "./pages/FlightAutomation";
import JudgingDay from "./pages/JudgingDay";
import Privacy from "./pages/Privacy";
import Scoresheets from "./pages/Scoresheets";
import * as Link from "./modules/Links";

function AppRouter() {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route path={Link.home.url} exact component={Home} />
                <Route path={Link.hops.url} exact component={Hops} />
                <Route path={Link.srms.url} exact component={Srms} />
                <Route path={Link.styles.url} exact component={Styles} />
                <Route path={Link.yeasts.url} exact component={Yeasts} />
                <Route path={Link.data.url} exact component={Data} />
                <Route path={Link.about.url} exact component={About} />
                <Route
                    path={Link.competitions.url}
                    exact
                    component={Competition}
                />
                <Route
                    path={Link.entryProcess.url}
                    exact
                    component={EntryProcess}
                />
                <Route
                    path={Link.flightAutomation.url}
                    exact
                    component={FlightAutomation}
                />
                <Route
                    path={Link.judgingDay.url}
                    exact
                    component={JudgingDay}
                />
                <Route path={Link.privacy.url} exact component={Privacy} />
                <Route
                    path={Link.scoresheets.url}
                    exact
                    component={Scoresheets}
                />
            </Switch>
        </Router>
    );
}

export default AppRouter;
