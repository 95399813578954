import { colors } from "../../theme";
import styled from "styled-components";

export const DataCard = styled.a`
    display: block;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    -webkit-transition: background-color 200ms ease-out;
    -moz-transition: background-color 200ms ease-out;
    -o-transition: background-color 200ms ease-out;
    transition: background-color 200ms ease-out;
    :hover {
        background-color: rgb(0, 0, 0, 0.25);
    }
`;

export const CodeBlock = styled.code`
    background-color: #1e1e1e;
    padding: 8px 10px;
    border-radius: 4px;
    color: ${colors.orange_light};
    margin-bottom: 20px;
    display: inline-block;
`;
