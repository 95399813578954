import api from ".";

class Styles {
    route = "/styles";

    get = filter => {
        return api.get(this.route);
    };
}

export default new Styles();
