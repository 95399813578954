import React from "react";
import { Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { CaretRightOutlined } from "@ant-design/icons";
import BrewerwallIcon from "../BrewerwallIcon";
import { LayoutRowDark } from "../PrimaryLayout/styles";
import { Anchor } from "./styles";
import * as Link from "../Links";

function RepositoryListRow() {
    const history = useHistory();

    return (
        <Fade>
            <LayoutRowDark type="flex" justify="center" align="middle">
                <Col xs={0} sm={10} md={8} style={{ textAlign: "right" }}>
                    <h1 style={{ color: "white" }}>Data Repositories</h1>
                    <p>
                        A full repository of data that can be searched and
                        connected through our API.
                    </p>
                    <hr />
                    <Button
                        type="link"
                        size="default"
                        style={{marginRight: -16}}
                        onClick={() => history.push(Link.data.url)}
                    >
                        Read More <CaretRightOutlined />
                    </Button>
                </Col>
                <Col
                    xs={5}
                    sm={{ span: 3, offset: 1 }}
                    md={{ span: 2, offset: 1 }}
                    style={{ textAlign: "center" }}
                >
                    <Anchor onClick={() => history.push(Link.styles.url)}>
                        <BrewerwallIcon type="beer" width={40} />
                        <div>Styles</div>
                    </Anchor>
                </Col>
                <Col xs={5} sm={3} md={2} style={{ textAlign: "center" }}>
                    <Anchor onClick={() => history.push(Link.hops.url)}>
                        <BrewerwallIcon type="hops" width={40} />
                        <div>Hops</div>
                    </Anchor>
                </Col>
                <Col xs={5} sm={3} md={2} style={{ textAlign: "center" }}>
                    <Anchor onClick={() => history.push(Link.yeasts.url)}>
                        <BrewerwallIcon type="yeast" width={40} />
                        <div>Yeasts</div>
                    </Anchor>
                </Col>
                <Col xs={5} sm={3} md={2} style={{ textAlign: "center" }}>
                    <Anchor onClick={() => history.push(Link.srms.url)}>
                        <BrewerwallIcon type="srm" width={40} />
                        <div>Srms</div>
                    </Anchor>
                </Col>
            </LayoutRowDark>
        </Fade>
    );
}

export default RepositoryListRow;
