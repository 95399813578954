import React from "react";
import { Layout, Col, Row, Divider, Typography } from "antd";
import { useHistory } from "react-router-dom";
import SocialLinks from "../SocialLinks";
import * as Link from "../Links";
import Logo from "@brewerwall/logo";

const { Text } = Typography;

const footerLogo = (
    <div>
        <a href={Link.home.url}>
            <Logo type="dark_solid" />
        </a>
        <div>Homebrew Competitions Simplified.</div>
    </div>
);

function FooterBar() {
    const history = useHistory();

    return (
        <Layout.Footer type="flex" justify="center" align="top">
            <Row>
                <Col xs={0} sm={24} md={10}>
                    {footerLogo}
                </Col>
                <Col xs={24} sm={0} md={0} style={{ textAlign: "center" }}>
                    {footerLogo}
                </Col>
                <Col xs={0} md={6} style={{ textAlign: "right" }}>
                    <Text strong>Features</Text>
                    <div style={{ marginRight: -8 }}>
                        {Link.anchor(Link.competitions, history)}
                        <br />
                        {Link.anchor(Link.entryProcess, history)}
                        <br />
                        {Link.anchor(Link.judgingDay, history)}
                        <br />
                        {Link.anchor(Link.flightAutomation, history)}
                        <br />
                        {Link.anchor(Link.scoresheets, history)}
                    </div>
                </Col>
                <Col xs={0} md={4} style={{ textAlign: "right" }}>
                    <Text strong>Repositories</Text>
                    <div style={{ marginRight: -8 }}>
                        {Link.anchor(Link.hops, history)}
                        <br />
                        {Link.anchor(Link.srms, history)}
                        <br />
                        {Link.anchor(Link.styles, history)}
                        <br />
                        {Link.anchor(Link.yeasts, history)}
                    </div>
                </Col>
                <Col xs={0} md={4} style={{ textAlign: "right" }}>
                    <Text strong>Company</Text>
                    <div style={{ marginRight: -8 }}>
                        {Link.anchor(Link.about, history)}
                        <br />
                        {Link.anchor(Link.privacy, history)}
                    </div>
                </Col>
            </Row>
            <Divider style={{ backgroundColor: "#CCC" }} />
            <Row align="middle">
                <Col xs={0} sm={12}>
                    <SocialLinks fontSize="4em" />
                </Col>
                <Col xs={24} sm={0} style={{ textAlign: "center" }}>
                    <SocialLinks fontSize="4em" />
                    <Divider style={{ backgroundColor: "#CCC" }} />
                </Col>
                <Col
                    xs={0}
                    sm={12}
                    style={{ textAlign: "right", paddingTop: "6px" }}
                >
                    <div>
                        <strong>Beta Coming Soon!</strong> &copy; 2022
                        Brewerwall
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={0}
                    style={{ textAlign: "center", paddingTop: "6px" }}
                >
                    <div>
                        <strong>Beta Coming Soon!</strong> &copy; 2022
                        Brewerwall
                    </div>
                </Col>
            </Row>
        </Layout.Footer>
    );
}

export default FooterBar;
