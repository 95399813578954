import Kitsu from "kitsu";

const api = new Kitsu({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "Content-Type": "application/vnd.api+json"
    },
    pluralize: false
});

export default api;
