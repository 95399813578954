import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import SearchableTable from "../../modules/SearchableTable";
import RepositoryLayout from "../../modules/RepositoryLayout";
import StylesApi from "../../api/styles";

function getRecordDataOriginalGravity(record) {
    return record.og_min === 0 && record.og_max === record.og_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.og_min} ({record.og_plato_min}&deg;) - {record.og_max} (
            {record.og_plato_max}&deg;)
        </span>
    );
}

function getRecordDataFinalGravity(record) {
    return record.fg_min === 0 && record.fg_max === record.fg_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.fg_min} ({record.fg_plato_min}&deg;) - {record.fg_max} (
            {record.fg_plato_max}&deg;)
        </span>
    );
}

function getRecordDataABW(record) {
    return record.abw_min === 0 && record.abw_max === record.abw_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.abw_min}% - {record.abw_max}%
        </span>
    );
}

function getRecordDataABV(record) {
    return record.abv_min === 0 && record.abv_max === record.abv_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.abv_min}% - {record.abv_max}%
        </span>
    );
}

function getRecordDataIBU(record) {
    return record.ibu_min === 0 && record.ibu_max === record.ibu_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.ibu_min} - {record.ibu_max}
        </span>
    );
}

function getRecordDataSRM(record) {
    return record.srm_min === 0 && record.srm_max === record.srm_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.srm_min} - {record.srm_max}
        </span>
    );
}

function getRecordDataEBC(record) {
    return record.ebc_min === 0 && record.ebc_max === record.ebc_min ? (
        <span>Any</span>
    ) : (
        <span>
            {record.ebc_min} - {record.ebc_max}
        </span>
    );
}

function getRecordDataGuideline(record) {
    return (
        <span>
            {record.style_guidelines.data.name} {record.style_guidelines.data.year}
        </span>
    );
}

function getDataColumns() {
    return [
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.name.localeCompare(b.name),
            searchable: true
        },
        {
            title: "ABV",
            key: "abv",
            render: getRecordDataABV,
            hideOnXs: true
        },
        {
            title: "IBU",
            key: "ibu",
            render: getRecordDataIBU,
            hideOnXs: true
        },
        {
            title: "Type",
            key: "type",
            dataIndex: "type",
            filterIndex: "type",
            hideOnXs: true,
            onFilter: (value, record) => record.type.includes(value)
        },
        {
            title: "Guideline",
            key: "style_guidelines",
            render: getRecordDataGuideline,
            filters: [
                { text: "BJCP 2015", value: "BJCP 2015" },
                { text: "BJCP 2021", value: "BJCP 2021" }
            ],
            onFilter: (value, record) =>
                record.style_guidelines.data.name + " " + record.style_guidelines.data.year === value
        }
    ];
}

function getExpandedRowRender() {
    return record => (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Row>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>ABV</h4>
                    {getRecordDataABV(record)}
                </Col>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>IBU</h4>
                    {getRecordDataIBU(record)}
                </Col>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>Type</h4>
                    {record.type}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Original Gravity</h4>
                    {getRecordDataOriginalGravity(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Final Gravity</h4>
                    {getRecordDataFinalGravity(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>ABW</h4>
                    {getRecordDataABW(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>SRM</h4>
                    {getRecordDataSRM(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>EBC</h4>
                    {getRecordDataEBC(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Guideline</h4>
                    {getRecordDataGuideline(record)}
                </Col>
            </Row>
            <Row>
                <Col span={22}>
                    <h4>Description</h4>
                    {record.description}
                </Col>
            </Row>
        </div>
    );
}

function Styles() {
    const [styles, setStyles] = useState([]);

    useEffect(() => {
        document.title += " - Styles";
    }, []);

    useEffect(() => {
        StylesApi.get().then(function(response) {
            setStyles(response.data);
        });
    }, []);

    return (
        <RepositoryLayout title="Styles" menu="styles" open="repositories">
            <SearchableTable
                columns={getDataColumns()}
                dataSource={styles}
                loading={styles.length === 0}
                expandedRowRender={getExpandedRowRender()}
            />
        </RepositoryLayout>
    );
}

export default Styles;
