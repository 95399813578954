import { colors } from "../../theme";
import styled from "styled-components";
import { Avatar } from "antd";

export const AvatarWrapper = styled.div`
    text-align: center;
    width: 100px;
    display: inline-block;
`;

export const AvatarName = styled.h3`
    color: white;
    margin-bottom: 0px;
    margin-top: 8px;
`;

export const AvatarTitle = styled.h4`
    color: white;
    font-weight: normal;
`;

export const AvatarImage = styled(Avatar)`
    border: solid ${colors.orange} 2px;
    box-shadow: 0px 0px 10px #111;
`;
