import React, { useState, useEffect } from "react";
import { message, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import RepositoryLayout from "../../modules/RepositoryLayout";
import SearchableTable from "../../modules/SearchableTable";
import SrmsApi from "../../api/srms";
import { CopyToClipboard } from "react-copy-to-clipboard";

function onCopy(hex) {
    message.success(hex + " Copied To Clipboard");
}

function getDataColumns() {
    return [
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.value - b.value
        },
        {
            title: "Hex",
            dataIndex: "hex",
            key: "hex",
            render: hex => (
                <div>
                    {hex}
                    <CopyToClipboard text={hex} onCopy={onCopy}>
                        <Button type="link">
                            <CopyOutlined />
                        </Button>
                    </CopyToClipboard>
                </div>
            )
        },
        {
            title: "Color",
            dataIndex: "hex",
            key: "color",
            render: hex => <div style={{ backgroundColor: hex }}>&nbsp;</div>
        }
    ];
}

function Srms() {
    const [srms, setSrms] = useState([]);

    useEffect(() => {
        document.title += " - Srms";
    }, []);

    useEffect(() => {
        SrmsApi.get().then(function(response) {
            setSrms(response.data);
        });
    }, []);

    return (
        <RepositoryLayout title="Srms" menu="srms" open="repositories">
            <SearchableTable
                columns={getDataColumns()}
                dataSource={srms}
                loading={srms.length === 0}
                pagination={false}
            />
        </RepositoryLayout>
    );
}

export default Srms;
