import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import SearchableTable from "../../modules/SearchableTable";
import RepositoryLayout from "../../modules/RepositoryLayout";
import HopsApi from "../../api/hops";
import _ from "lodash";

function getRecordDataAlpha(record) {
    return (
        <span>
            {record.alpha_min}% - {record.alpha_max}%
        </span>
    );
}

function getRecordDataBeta(record) {
    return (
        <span>
            {record.beta_min}% - {record.beta_max}%
        </span>
    );
}

function getRecordDataCohumulone(record) {
    return (
        <span>
            {record.cohumulone_min}% - {record.cohumulone_max}%
        </span>
    );
}

function getRecordDataTotalOil(record) {
    return (
        <span>
            {record.total_oil_min}ml/100g - {record.total_oil_max}ml/100g
        </span>
    );
}

function getRecordDataMyrcene(record) {
    return (
        <span>
            {record.myrcene_min}% - {record.myrcene_max}%
        </span>
    );
}

function getRecordDataHumulene(record) {
    return (
        <span>
            {record.humulene_min}% - {record.humulene_max}%
        </span>
    );
}

function getRecordDataFarnesene(record) {
    return (
        <span>
            {record.farnesene_min}% - {record.farnesene_max}%
        </span>
    );
}

function getRecordDataCaryophyllene(record) {
    return (
        <span>
            {record.caryophyllene_min}% - {record.caryophyllene_max}%
        </span>
    );
}

function getRecordDataAroma(record) {
    return <span>{record.aroma}</span>;
}

function getRecordDataStyles(record) {
    return <span>{record.styles}</span>;
}

function getRecordDataStorage(record) {
    return <span>{record.storage}</span>;
}

function getRecordDataSource(record) {
    return <span>{record.source}</span>;
}

function getRecordDataSubstitutes(record) {
    if (!record.hops) {
        return <span>None</span>;
    }

    return _.map(record.hops.data, function(hop) {
        return (
            <span key={hop.id}>
                {hop.name}
                <br />
            </span>
        );
    });
}

function getDataColumns() {
    return [
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.name.localeCompare(b.name),
            searchable: true
        },
        {
            title: "Origin",
            dataIndex: "origin",
            key: "origin",
            filterIndex: "origin",
            onFilter: (value, record) => {
                return record.origin.includes(value);
            }
        },
        {
            title: "Alpha",
            key: "alpha",
            render: getRecordDataAlpha,
            hideOnXs: true
        },
        {
            title: "Beta",
            key: "beta",
            render: getRecordDataBeta,
            hideOnXs: true
        }
    ];
}

function getExpandedRowRender() {
    return record => (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Row>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>Alpha</h4>
                    {getRecordDataAlpha(record)}
                </Col>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>Beta</h4>
                    {getRecordDataBeta(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Cohumulone</h4>
                    {getRecordDataCohumulone(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Total Oil</h4>
                    {getRecordDataTotalOil(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Myrcene</h4>
                    {getRecordDataMyrcene(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Humulene</h4>
                    {getRecordDataHumulene(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Farnesene</h4>
                    {getRecordDataFarnesene(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Caryophyllene</h4>
                    {getRecordDataCaryophyllene(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Aroma</h4>
                    {getRecordDataAroma(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Styles</h4>
                    {getRecordDataStyles(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Storage</h4>
                    {getRecordDataStorage(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={24} style={{ marginBottom: 16 }}>
                    <h4>Description</h4>
                    {record.description}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Substitutes</h4>
                    {getRecordDataSubstitutes(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Source</h4>
                    {getRecordDataSource(record)}
                </Col>
            </Row>
        </div>
    );
}

function Hops() {
    const [hops, setHops] = useState([]);

    useEffect(() => {
        document.title += " - Hops";
    }, []);

    useEffect(() => {
        HopsApi.get().then(function(response) {
            setHops(response.data);
        });
    }, []);

    return (
        <RepositoryLayout title="Hops" menu="hops" open="repositories">
            <SearchableTable
                columns={getDataColumns()}
                dataSource={hops}
                loading={hops.length === 0}
                expandedRowRender={getExpandedRowRender()}
            />
        </RepositoryLayout>
    );
}

export default Hops;
