import React from "react";
import { Table, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import { colors } from "../../theme/";
import MediaQuery from "react-responsive";

class SearchableTable extends React.Component {
    state = {
        searchText: "",
        filterList: null
    };

    getResponsiveColumns = (matches, columns) => {
        return columns.filter(
            ({ hideOnXs = false }) => !(matches && hideOnXs)
        );
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => this.handleSearchReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? colors.orange : null }} />
        ),
        onFilter: (value, record) =>
            _.get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: colors.orange, padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleSearchReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filterList: filters
        });
    };

    handleClearFilters = () => {
        this.setState({ filterList: null, searchText: "" });
    };

    getFilterList(data, field) {
        return _.chain(data)
            .uniqBy(field)
            .sortBy(field)
            .map(object => {
                return {
                    text: _.get(object, field),
                    value: _.get(object, field)
                };
            })
            .value();
    }

    render() {
        // let { filterList } = this.state;
        // filterList = filterList || {};
        let columns = _.map(this.props.columns, column => {
            if (column.searchable) {
                column = _.merge(column, {
                    ...this.getColumnSearchProps(column.dataIndex)
                });
            }

            if (column.filterIndex) {
                // let filteredValue =
                //     _.get(filterList, column.filterIndex, []).length === 0
                //         ? null
                //         : _.get(filterList, column.filterIndex);
                column = _.merge(column, {
                    //filteredValue: filteredValue,
                    filters: this.getFilterList(
                        this.props.dataSource,
                        column.filterIndex
                    )
                });
            }

            return column;
        });

        return (
            <div>
                <MediaQuery maxWidth={576}>
                    {matches => {
                        return (
                            <Table
                                size="small"
                                pagination={{ defaultPageSize: 20 }}
                                rowKey="id"
                                onChange={this.handleChange}
                                {...this.props}
                                columns={this.getResponsiveColumns(matches, columns)}
                            />
                        );
                    }}
                </MediaQuery>
            </div>
        );
    }
}

export default SearchableTable;
