import api from "./";

class Srms {
    route = "/srms";

    get = filter => {
        return api.get(this.route);
    };
}

export default new Srms();
