import React, { useEffect } from "react";
import { Col, Button } from "antd";
import MediaQuery from "react-responsive";
import { Fade } from "react-awesome-reveal";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import * as Link from "../../modules/Links";
import Logo from "@brewerwall/logo";
import RepositoryListRow from "../../modules/RepositoryListRow";

function Home() {
    const history = useHistory();

    useEffect(() => {
        document.title += " - Homebrew Competitions Simplified";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col span={22}>
                        <MediaQuery minWidth={600}>
                            <Logo size="hero" showTitle={true} />
                        </MediaQuery>
                        <MediaQuery maxWidth={600}>
                            <Logo size="hero" showTitle={false} />
                        </MediaQuery>
                        <BannerTitle>
                            Homebrew Competitions Simplified.
                        </BannerTitle>
                        <BannerSubTitle>
                            Competition management, tools and resources for
                            homebrewers.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <br />
                <Fade>
                    <LayoutRow
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ marginBottom: 30, marginTop: 20 }}
                    >
                        <Col
                            xs={0}
                            sm={6}
                            md={4}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="medal" width={"70%"} />
                        </Col>
                        <Col
                            xs={8}
                            sm={0}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="medal" width={"100%"} />
                        </Col>
                        <BubbleBlock xs={20} sm={14} md={16}>
                            <h1>Competition Management</h1>
                            <hr />
                            <p>
                                No more are the days of spreadsheets and
                                paperwork. A centralized place to manage all of
                                the competition hassles. Control entrants,
                                manages dropoff and judging locations, automated
                                judging day organization and scoresheet return
                                processes.
                            </p>
                            <p className="readMore">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() =>
                                        history.push(Link.competitions.url)
                                    }
                                >
                                    Read More <CaretRightOutlined />
                                </Button>
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <Fade>
                    <LayoutRow
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ marginBottom: 30 }}
                    >
                        <Col
                            xs={0}
                            sm={6}
                            md={4}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="entry" width={"80%"} />
                        </Col>
                        <Col
                            xs={8}
                            sm={0}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="entry" width={"100%"} />
                        </Col>
                        <BubbleBlock xs={20} sm={14} md={16}>
                            <h1>Entry Process</h1>
                            <hr />
                            <p>
                                Submitting is easy as applying to a competition
                                and mailing off a homebrew. Locations and labels
                                are generated for you and any payments processes
                                are handled online.
                            </p>
                            <p className="readMore">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() =>
                                        history.push(Link.entryProcess.url)
                                    }
                                >
                                    Read More <CaretRightOutlined />
                                </Button>
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <Fade>
                    <LayoutRow
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ marginBottom: 30 }}
                    >
                        <Col
                            xs={0}
                            sm={6}
                            md={4}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="judging" width={"85%"} />
                        </Col>
                        <Col
                            xs={8}
                            sm={0}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="judging" width={"100%"} />
                        </Col>
                        <BubbleBlock xs={20} sm={14} md={16}>
                            <h1>Judging Day</h1>
                            <hr />
                            <p>
                                Judge and Steward management eases the burden of
                                judging day. Judges and Stewards will have their
                                own accounts to be able to sign-up and attend
                                competitions. Barcoded entries and judge
                                submission tracking creates an easy and
                                efficient workflow of the day.
                            </p>
                            <p className="readMore">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() =>
                                        history.push(Link.judgingDay.url)
                                    }
                                >
                                    Read More <CaretRightOutlined />
                                </Button>
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <Fade>
                    <LayoutRow
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ marginBottom: 30 }}
                    >
                        <Col
                            xs={0}
                            sm={6}
                            md={4}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="flight" width={"65%"} />
                        </Col>
                        <Col
                            xs={8}
                            sm={0}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="flight" width={"85%"} />
                        </Col>
                        <BubbleBlock xs={20} sm={14} md={16}>
                            <h1>Flight Automation</h1>
                            <hr />
                            <p>
                                Organizing, grouping and distributing flights to
                                the correct judging table can be a tedious and
                                difficult task. With Brewerwall, we aim to
                                automate this. Based on competition style
                                limitations, judge's style preferences, quantity
                                of entries and number of judges we can automat
                                the organization of your judging day!
                            </p>
                            <p className="readMore">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() =>
                                        history.push(Link.flightAutomation.url)
                                    }
                                >
                                    Read More <CaretRightOutlined />
                                </Button>
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <Fade>
                    <LayoutRow
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{ marginBottom: 30 }}
                    >
                        <Col
                            xs={0}
                            sm={6}
                            md={4}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="scoresheet" width={"45%"} />
                        </Col>
                        <Col
                            xs={8}
                            sm={0}
                            style={{ textAlign: "center", marginBottom: 20 }}
                        >
                            <BrewerwallIcon type="scoresheet" width={"65%"} />
                        </Col>
                        <BubbleBlock xs={20} sm={14} md={16}>
                            <h1>Scoresheets</h1>
                            <hr />
                            <p>
                                No more hassles with juggling scoresheets. With
                                the ability to print, scan and store all of the
                                competition's scoresheets in a central location,
                                it makes things incredibily easy for everyone.
                            </p>
                            <p className="readMore">
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() =>
                                        history.push(Link.scoresheets.url)
                                    }
                                >
                                    Read More <CaretRightOutlined />
                                </Button>
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default Home;
