export const colors = {
    primary: "#0B5177",
    secondary: "#BFA879",
    background: "#FFFFFF",
    red: "#ff2e0b",
    red_light: "#ff8675",
    blue: "#0078a8",
    blue_light: "#57aacf",
    green: "#00c33d",
    green_light: "#2fde84",
    orange: "#ff9900",
    orange_light: "#ffc16f",

    bg_dark_opacity_20: "url('/img/dark_opacity_20.png')",
    bg_dark_opacity_50: "url('/img/dark_opacity_50.png')",
    bg_dark_opacity_70: "url('/img/dark_opacity_70.png')",
    bg_dark_opacity_80: "url('/img/dark_opacity_80.png')",
    bg_dark_opacity_90: "url('/img/dark_opacity_90.png')",
    bg_light_opacity_20: "url('/img/light_opacity_20.png')",
    bg_light_opacity_50: "url('/img/light_opacity_50.png')",
    bg_row_light: "url('/img/row_light.png')"
};
