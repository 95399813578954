import React  from "react";
import { Button } from "antd";
import { GithubOutlined, TwitterOutlined, InstagramOutlined, FacebookOutlined } from "@ant-design/icons";

function SocialLinks(props) {
    return (
        <span>
            <Button
                type="link"
                target="_blank"
                icon={<GithubOutlined />}
                href="https://github.com/brewerwall"
            />
            <Button
                type="link"
                target="_blank"
                icon={<TwitterOutlined />}
                href="https://twitter.com/brewerwall"
            />
            <Button
                type="link"
                target="_blank"
                icon={<InstagramOutlined />}
                href="https://www.instagram.com/brewerwallapp/"
            />
            <Button
                type="link"
                target="_blank"
                icon={<FacebookOutlined />}
                href="https://www.facebook.com/brewerwallapp"
            />
        </span>
    );
}

export default SocialLinks;
