import api from ".";

class Yeasts {
    route = "/yeasts";

    get = filter => {
        return api.get(this.route);
    };
}

export default new Yeasts();
