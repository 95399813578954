import React from "react";
import { useHistory } from "react-router-dom";
import { Layout, Col, Button, Menu, Row, Dropdown, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "@brewerwall/logo";
import * as Link from "../Links";
import SignUp from "../SignUp";

function wrapMenuItem(link, key) {
    return <Menu.Item key={key}>{link}</Menu.Item>;
}

const renderMenu = (history) => {
    return (
        <Menu>
            <Menu.ItemGroup title="Features">
                {wrapMenuItem(
                    Link.anchor(Link.competitions, history),
                    "competitions"
                )}
                {wrapMenuItem(
                    Link.anchor(Link.entryProcess, history),
                    "entryProcess"
                )}
                {wrapMenuItem(
                    Link.anchor(Link.judgingDay, history),
                    "judgingDay"
                )}
                {wrapMenuItem(
                    Link.anchor(Link.flightAutomation, history),
                    "flightAutomation"
                )}
                {wrapMenuItem(
                    Link.anchor(Link.scoresheets, history),
                    "scoresheets"
                )}
                {wrapMenuItem(Link.anchor(Link.data, history), "data")}
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Repositories">
                {wrapMenuItem(Link.anchor(Link.hops, history), "hops")}
                {wrapMenuItem(Link.anchor(Link.srms, history), "srms")}
                {wrapMenuItem(Link.anchor(Link.styles, history), "beerStyles")}
                {wrapMenuItem(Link.anchor(Link.yeasts, history), "yeasts")}
            </Menu.ItemGroup>
            <Menu.Item>
                <SignUp buttonStyle={{ width: "100%" }} />
            </Menu.Item>
        </Menu>
    );
};

const renderRepositories = (history) => {
    return (
        <Menu>
            {wrapMenuItem(Link.anchor(Link.hops, history), "hops")}
            {wrapMenuItem(Link.anchor(Link.srms, history), "srms")}
            {wrapMenuItem(Link.anchor(Link.styles, history), "beerStyles")}
            {wrapMenuItem(Link.anchor(Link.yeasts, history), "yeasts")}
        </Menu>
    );
};

const renderFeatures = (history) => {
    return (
        <Menu>
            {wrapMenuItem(
                Link.anchor(Link.competitions, history),
                "competitions"
            )}
            {wrapMenuItem(
                Link.anchor(Link.entryProcess, history),
                "entryProcess"
            )}
            {wrapMenuItem(Link.anchor(Link.judgingDay, history), "judgingDay")}
            {wrapMenuItem(
                Link.anchor(Link.flightAutomation, history),
                "flightAutomation"
            )}
            {wrapMenuItem(
                Link.anchor(Link.scoresheets, history),
                "scoresheets"
            )}
            {wrapMenuItem(Link.anchor(Link.data, history), "data")}
        </Menu>
    );
};

function MenuBar() {
    const history = useHistory();

    return (
        <Layout.Header
            style={{
                background: "rgba(0, 0, 0, .9)",
                position: "fixed",
                width: "100%",
                zIndex: 100,
                padding: "0px 16px",
            }}
        >
            <Row>
                <Col xs={18} sm={10} style={{ paddingTop: 10 }}>
                    <a href={Link.home.url}>
                        <Logo
                            type="light_solid"
                            textColor="white"
                            size="medium"
                        />
                    </a>
                </Col>
                <Col xs={0} sm={0} md={14} style={{ textAlign: "right" }}>
                    <Space size="small">
                        <Dropdown
                            overlay={renderFeatures(history)}
                            trigger={["click"]}
                        >
                            <Button type="link" size="large">
                                Features
                            </Button>
                        </Dropdown>
                        <Dropdown
                            overlay={renderRepositories(history)}
                            trigger={["click"]}
                        >
                            <Button type="link" size="large">
                                Repositories
                            </Button>
                        </Dropdown>
                        <SignUp size="default" />
                    </Space>
                </Col>
                <Col xs={6} sm={14} md={0} style={{ textAlign: "right" }}>
                    <Dropdown
                        overlay={renderMenu(history)}
                        placement="bottomRight"
                        style={{ float: "right" }}
                        trigger={["click"]}
                    >
                        <Button type="primary">
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </Layout.Header>
    );
}

export default MenuBar;
