import React, { useEffect } from "react";
import { Col } from "antd";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    BannerRow,
    BannerTitle,
    BubbleBlock
} from "../../modules/PrimaryLayout/styles";
import { AvatarWrapper, AvatarName, AvatarTitle, AvatarImage } from "./styles";
import RepositoryListRow from "../../modules/RepositoryListRow";

function About() {
    useEffect(() => {
        document.title += " - About";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col sm={14} style={{ textAlign: "center" }}>
                        <BannerTitle>
                            Our Mission is to Make Homebrew Competitions Simple.
                        </BannerTitle>
                    </Col>
                </BannerRow>
                <LayoutRow type="flex" justify="center">
                    <BubbleBlock xs={20} sm={10} style={{ marginBottom: 20 }}>
                        <h2 style={{ color: "white" }}>Starting in 2014</h2>
                        <hr />
                        <p>
                            Brewerwall started as a hub in which data is
                            combined into a single location and accessible by
                            all. Either through API services or through this
                            site, anyone can take a look at elements used in
                            brewing beer. Also provided are utilities that can
                            be used in a variety of programming languages.
                            Sourcecode is available on{" "}
                            <a
                                href="https://github.com/brewerwall/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Github
                            </a>{" "}
                            and can be easily installed through package managers
                            like{" "}
                            <a
                                href="https://www.npmjs.org/package/beercalc_js"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                npm
                            </a>
                            ,{" "}
                            <a
                                href="https://packagist.org/packages/brewerwall/beercalc_php"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                composer
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://rubygems.org/gems/beercalc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                gem
                            </a>
                            .
                        </p>
                    </BubbleBlock>
                    <BubbleBlock
                        xs={20}
                        sm={{ span: 10, offset: 1 }}
                        style={{ marginBottom: 20 }}
                    >
                        <h2 style={{ color: "white" }}>Now</h2>
                        <hr />
                        <p>
                            Brewerwall is working towards becoming a homebrew
                            competition management system. The mission is to
                            make finding, entering and making homebrew
                            competitions as simple as possible. Starting in
                            2019, we hope to release the first beta of our
                            service in the fall.
                        </p>
                    </BubbleBlock>
                    <BubbleBlock xs={20} sm={10} style={{ marginBottom: 20 }}>
                        <h2 style={{ color: "white" }}>Get In Touch</h2>
                        <hr />
                        <p>
                            For any questions, comments or complaints please
                            reach out to{" "}
                            <a href="mailto:info@brewerwall.com">
                                info@brewerwall.com
                            </a>
                            . We will be sure to get back to you as soon as
                            possible!
                        </p>
                    </BubbleBlock>
                    <BubbleBlock
                        xs={20}
                        sm={{ span: 10, offset: 1 }}
                        style={{ marginBottom: 20 }}
                    >
                        <h2 style={{ color: "white" }}>The Team</h2>
                        <hr />
                        <p>
                            We are a very small team. In fact, its just a team
                            of one!
                        </p>
                        <p>
                            <AvatarWrapper>
                                <AvatarImage
                                    size={100}
                                    src="/img/ben_griffith.jpg"
                                />
                                <AvatarName>Ben Griffith</AvatarName>
                                <AvatarTitle>Founder</AvatarTitle>
                            </AvatarWrapper>
                        </p>
                    </BubbleBlock>
                </LayoutRow>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default About;
