import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowLight,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import SignUp from "../../modules/SignUp";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import RepositoryListRow from "../../modules/RepositoryListRow";

function Competition() {
    useEffect(() => {
        document.title += " - Competitions";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={10} sm={6} md={4} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="medal" width={"85%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Competition Management</BannerTitle>
                        <BannerSubTitle>
                            No more are the days of spreadsheets and paperwork.
                            A centralized place to manage all of the competition
                            hassles.
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Competition Management
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            No more are the days of spreadsheets and paperwork.
                            A centralized place to manage all of the competition
                            hassles.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRow type="flex" justify="center">
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Manage Your Competition
                            </h2>
                            <hr />
                            <p>
                                You are in full control of all aspects the
                                competition. You can make your competition as
                                large or as focused as you need it to be.
                                Whether it's for a small homebrew club
                                competition to a multi-state BJCP sanctioned
                                competition with a thousand entries, Brewerwall
                                can help!
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Rules, Dates and Locations
                            </h2>
                            <hr />
                            <p>
                                Setting up rules are straight forward. Limiting
                                entries to styles and entry amounts can be
                                easily managed through Brewerwall's competition
                                dashboard. Setting up entry location and
                                deadlines along with juding locations are a
                                breeze.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Judge and Steward Management
                            </h2>
                            <hr />
                            <p>
                                The ability to search and invite judges and
                                stewards are easy. Search for existing judges
                                and stewards in your area to invite. If they are
                                new, easily send them an invite via email. From
                                there, you can coordinate who needs to be where
                                and when!
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Public and Private Competitions
                            </h2>
                            <hr />
                            <p>
                                Working on a private competition amungst your
                                club or starting a multi-state competition?
                                Easily specify if you want your competition to
                                be searched or by inivitation only. Limit who
                                can enter your competition by validating
                                entrants.
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <LayoutRowLight type="flex" justify="center" align="top">
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <h1>Sign Up For Updates and Beta Access</h1>
                        <SignUp style={{ marginBottom: "20px" }} size="large" />
                    </Col>
                </LayoutRowLight>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default Competition;
