import React from "react";
import { Layout } from "antd";
import MenuBar from "../MenuBar";
import FooterBar from "../FooterBar";
import { Title } from "./styles.js";

function RepositoryLayout(props) {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <MenuBar />
            <Layout style={{ marginTop: 64 }}>
                <Layout.Content style={{ margin: "0 16px" }}>
                    <div
                        style={{
                            marginTop: 16,
                            padding: 24,
                            background: "#fff",
                            minHeight: 360
                        }}
                    >
                        <Title>{props.title}</Title>
                        {props.children}
                    </div>
                </Layout.Content>
            </Layout>
            <FooterBar />
        </Layout>
    );
}

export default RepositoryLayout;
