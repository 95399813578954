import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowLight,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import SignUp from "../../modules/SignUp";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import RepositoryListRow from "../../modules/RepositoryListRow";

function JudgingDay() {
    useEffect(() => {
        document.title += " - Judging Day";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={12} sm={6} md={5} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="judging" width={"100%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Judging Day</BannerTitle>
                        <BannerSubTitle>
                            Managing judges and stewards will be streamlined
                            with Brewerwall. Schedule and organize your entire
                            judging day in one spot.
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Judging Day
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            Managing judges and stewards will be streamlined
                            with Brewerwall. Schedule and organize your entire
                            judging day in one spot.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRow type="flex" justify="center">
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Organize Sessions and Locations
                            </h2>
                            <hr />
                            <p>
                                Scheduling sessions and locations is a breeze.
                                Assign participating stewards and judges to
                                pre-determined locations. Brewerwall will remind
                                and confirm stewards and judges for those
                                locations. Once confirmed, Brewerwall will
                                assist with generating tables and assigning
                                flights for judges and stewards.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Smart Flight/Judge Pairing
                            </h2>
                            <hr />
                            <p>
                                Brewerwall will do its best to arrange flights
                                with judges taking into account the judges style
                                preference. No need to worry abou that judge
                                that hates stouts, getting on a flight of souts!
                                However, you will always be in control, manually
                                organizaing the flights will always be an
                                option.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Barcode Scanned Entries
                            </h2>
                            <hr />
                            <p>
                                Barcodes are the easiest way to track an entry.
                                Automated labels made and applied to the
                                homebrew, scoresheets marked with the barcode
                                and the inputting the results are all referenced
                                and tracked with the barcode.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Judge and Steward Reminders
                            </h2>
                            <hr />
                            <p>
                                Sometimes people forget things. Life happens!
                                Brewerwall will be there to assist in reminding
                                and confirming everyone makes it to the sessions
                                they need to be at.
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <LayoutRowLight type="flex" justify="center" align="top">
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <h1>Sign Up For Updates and Beta Access</h1>
                        <SignUp style={{ marginBottom: "20px" }} size="large" />
                    </Col>
                </LayoutRowLight>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default JudgingDay;
