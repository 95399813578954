import React from "react";
import { Button } from "antd";

export const hops = {
    name: "Hops",
    url: "/hops",
};

export const srms = {
    name: "SRMs",
    url: "/srms",
};

export const styles = {
    name: "Styles",
    url: "/styles",
};

export const yeasts = {
    name: "Yeasts",
    url: "/yeasts",
};

export const competitions = {
    name: "Competitions",
    url: "/competition",
};

export const entryProcess = {
    name: "Entry Process",
    url: "/entry-process",
};

export const judgingDay = {
    name: "Judging Day",
    url: "/judging-day",
};

export const flightAutomation = {
    name: "Flight Automation",
    url: "/flight-automation",
};

export const scoresheets = {
    name: "Scoresheets",
    url: "/scoresheets",
};

export const about = {
    name: "About",
    url: "/about",
};

export const blog = {
    name: "Blog",
    url: "/blog",
};

export const data = {
    name: "Data",
    url: "/data",
};

export const privacy = {
    name: "Privacy",
    url: "/privacy",
};

export const home = {
    name: "Home",
    url: "/",
};

export function anchor(link, history) {
    return (
        <Button size="small" type="link" onClick={() => history.push(link.url)}>
            {link.name}
        </Button>
    );
}
