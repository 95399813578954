import { colors } from "../../theme/";
import styled from "styled-components";
import { Row, Col } from "antd";

export const BubbleBlock = styled(Col)`
    background-color: #464646;
    padding: 16px 24px 0px 24px;
    border-radius: 8px;
    color: white;
    h1, h2 {
        color: white;
    }
    p {
        font-size: 16px;
    }
    p.readMore {
        padding-bottom: 8px;
        text-align: right;
    }
`;

export const SecondarySpacer = styled(Row)`
    text-align: center;
    height: 0px;
    padding-top: 70px;
`;

export const LayoutRow = styled(Row)`
    padding: 20px 0px 20px 0px;
    color: white;
`;

export const LayoutRowDark = styled(LayoutRow)`
    background-image: ${colors.bg_dark_opacity_20};
`;

export const LayoutRowLight = styled(LayoutRow)`
    background-image: ${colors.bg_row_light};
`;

export const BannerRow = styled(Row)`
    background-image: ${colors.bg_row_light};
    padding-top: 110px;
    padding-bottom: 40px;
    text-align: center;
`;

export const BannerTitle = styled.h1`
    font-size: 3em;
    color: black;
    margin-bottom: 0px;
`;

export const BannerSubTitle = styled.h2`
    color: black;
`;
