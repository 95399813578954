import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import SearchableTable from "../../modules/SearchableTable";
import RepositoryLayout from "../../modules/RepositoryLayout";
import YeastsApi from "../../api/yeasts";
import _ from "lodash";

function getRecordDataAttenuation(record) {
    if (
        record.attenuation_min === 0 &&
        record.attenuation_min === record.attenuation_max
    ) {
        return <span>N/A</span>;
    } else if (record.attenuation_min === record.attenuation_max) {
        return <span>{record.attenuation_min}%+</span>;
    }

    return (
        <span>
            {record.attenuation_min}% - {record.attenuation_max}%
        </span>
    );
}

function getRecordDataTemperature(record) {
    if (
        record.temperature_min === 0 &&
        record.temperature_min === record.temperature_max
    ) {
        return <span>N/A</span>;
    } else if (record.temperature_min === record.temperature_max) {
        return <span>{record.temperature_min}%+</span>;
    }

    return (
        <span>
            {record.temperature_min}&#176;F - {record.temperature_max}&#176;F
        </span>
    );
}

function getRecordDataTolerance(record) {
    return record.tolerance === 0 || _.isEmpty(record.flocculation) ? (
        <span>N/A</span>
    ) : (
        <span>{record.tolerance}%</span>
    );
}

function getRecordDataFlocculation(record) {
    return record.flocculation === 0 || _.isEmpty(record.flocculation) ? (
        <span>N/A</span>
    ) : (
        <span>{record.flocculation}</span>
    );
}

function getDataColumns() {
    return [
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.name.localeCompare(b.name),
            searchable: true
        },
        {
            title: "Lab",
            key: "laboratory",
            dataIndex: "laboratory",
            filterIndex: "laboratory",
            onFilter: (value, record) => record.laboratory.includes(value)
        },
        {
            title: "Attenuation",
            key: "attenuation",
            render: getRecordDataAttenuation,
            hideOnXs: true
        },
        {
            title: "Temperature",
            key: "temperature",
            render: getRecordDataTemperature,
            hideOnXs: true
        }
    ];
}

function getExpandedRowRender() {
    return record => (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Row>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>Attenuation</h4>
                    {getRecordDataAttenuation(record)}
                </Col>
                <Col sm={0} style={{ marginBottom: 16 }}>
                    <h4>Temperature</h4>
                    {getRecordDataTemperature(record)}
                </Col>
            </Row>
            <Row>
                <Col sm={7} style={{ marginBottom: 16 }}>
                    <h4>Floccuation</h4>
                    {getRecordDataFlocculation(record)}
                </Col>
                <Col sm={{ span: 7, offset: 1 }} style={{ marginBottom: 16 }}>
                    <h4>Max Tolerance</h4>
                    {getRecordDataTolerance(record)}
                </Col>
            </Row>
            <Row>
                <Col span={22}>
                    <h4>Description</h4>
                    {record.description}
                </Col>
            </Row>
        </div>
    );
}

function Yeasts() {
    const [yeasts, setYeasts] = useState([]);

    useEffect(() => {
        document.title += " - Yeasts";
    }, []);

    useEffect(() => {
        YeastsApi.get().then(function(response) {
            setYeasts(response.data);
        });
    }, []);

    return (
        <RepositoryLayout title="Yeasts" menu="yeasts" open="repositories">
            <SearchableTable
                columns={getDataColumns()}
                dataSource={yeasts}
                loading={yeasts.length === 0}
                expandedRowRender={getExpandedRowRender()}
            />
        </RepositoryLayout>
    );
}

export default Yeasts;
