import api from "./";

class Signup {
    route = "/signup";

    post = (firstName, lastName, email) => {
        let values = {
            first_name: firstName,
            last_name: lastName,
            email: email
        };
        return api.post(this.route, values);
    };
}

export default new Signup();
