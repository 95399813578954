import React, { useEffect } from "react";
import { Col } from "antd";
import { Fade } from "react-awesome-reveal";
import PrimaryLayout from "../../modules/PrimaryLayout";
import {
    LayoutRow,
    LayoutRowLight,
    BannerRow,
    BannerTitle,
    BannerSubTitle,
    BubbleBlock,
} from "../../modules/PrimaryLayout/styles";
import SignUp from "../../modules/SignUp";
import BrewerwallIcon from "../../modules/BrewerwallIcon";
import RepositoryListRow from "../../modules/RepositoryListRow";

function FlightAutomation() {
    useEffect(() => {
        document.title += " - Flight Automation";
    });

    return (
        <div>
            <PrimaryLayout>
                <BannerRow type="flex" justify="center" align="middle">
                    <Col xs={10} sm={6} md={5} style={{ textAlign: "center" }}>
                        <BrewerwallIcon type="flight" width={"85%"} />
                    </Col>
                    <Col xs={0} sm={14} offset={1}>
                        <BannerTitle>Flight Automation</BannerTitle>
                        <BannerSubTitle>
                            Organizing, grouping and distributing flights to the
                            correct judging table can be a tedious and difficult
                            task. With Brewerwall, we aim to automate this.
                        </BannerSubTitle>
                    </Col>
                    <Col
                        xs={20}
                        sm={0}
                        style={{ textAlign: "center", marginTop: 20 }}
                    >
                        <BannerTitle style={{ fontSize: "2em" }}>
                            Flight Automation
                        </BannerTitle>
                        <BannerSubTitle style={{ fontSize: "1.25em" }}>
                            Organizing, grouping and distributing flights to the
                            correct judging table can be a tedious and difficult
                            task. With Brewerwall, we aim to automate this.
                        </BannerSubTitle>
                    </Col>
                </BannerRow>
                <Fade>
                    <LayoutRow type="flex" justify="center">
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Judge Preferences
                            </h2>
                            <hr />
                            <p>
                                Judges can set their own preferences for styles
                                they prefer. Finding a judge with a style
                                preference is great for styles you are judging
                                on. This gives a better judgement on the flights
                                they recieve.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Automated Flight Assignment
                            </h2>
                            <hr />
                            <p>
                                Creating tables with flights and judges can be a
                                chore. Especially when you have a decent amount
                                of entries to keep track of. Brewerwall will
                                ease that burden by taking into account your
                                entries, their groupings and judging preferences
                                to formulate the arrangement for you.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={10}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>
                                Grouping Entry Styles
                            </h2>
                            <hr />
                            <p>
                                Sometimes there aren't enough entries to warrant
                                a single styled flight. In cases like this, it's
                                great to group multiple entries by a parent or
                                custom style definition. Brewerwall helps
                                facilitate this by letting you group your
                                entries into similar categories.
                            </p>
                        </BubbleBlock>
                        <BubbleBlock
                            xs={20}
                            sm={{ span: 10, offset: 1 }}
                            style={{ marginBottom: 20 }}
                        >
                            <h2 style={{ color: "white" }}>Full Control</h2>
                            <hr />
                            <p>
                                If automation isn't your thing, no worries! You
                                will still have full control of setting up
                                sessions, tables, flights, judges and every
                                other aspect of your competition.
                            </p>
                        </BubbleBlock>
                    </LayoutRow>
                </Fade>
                <LayoutRowLight type="flex" justify="center" align="top">
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <h1>Sign Up For Updates and Beta Access</h1>
                        <SignUp style={{ marginBottom: "20px" }} size="large" />
                    </Col>
                </LayoutRowLight>
                <RepositoryListRow />
            </PrimaryLayout>
        </div>
    );
}

export default FlightAutomation;
